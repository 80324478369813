$primary-color: #d1d5db;

.search {
	&::placeholder {
		color: $primary-color !important;
	}
}

.search:focus {
	outline: #d1d5db 1px solid;
	border-radius: 4px;
}
