@use "sass:map";

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
);

.design {
	display: block;

	@media screen and (max-width: map.get($grid-breakpoints, sm)) {
		display: none;
	}
}
