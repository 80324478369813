.loading {
    position: absolute;
    
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    height: 30px;
    width: 30px;
    border: 2px solid #ddd;
    border-left-color: #009688;
    border-radius: 30px; /* border-radius: 50% */
    -webkit-animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
    animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
  }
  
  @-webkit-keyframes animation-rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes animation-rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @media screen and (min-width: 550px){
    .row-loading{
      margin-top: 30%;
      margin-left:35% ;
      
    } 
}
@media screen and (max-width: 550px){
  .row-loading{
    margin-top: 100%;
    
    
  } 
}
.secedit{
  margin-top: 30%;
  margin-left: 10%;
}