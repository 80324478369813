
@import '~@fullcalendar/daygrid/main.css';

/*@media screen and (min-width: 550px){
    .log-margin{
        margin-top: 200px;
        
        position: relative;
        padding-left: 20;
        opacity: 0.9;
        z-index: 200;
    
    }
}

 .row-content{
     margin-top:100px;
     
 }
 .row-list{
     margin-top: 10px;
 }

.navcolor{
    color: black;
    margin: 3;

}
 
  .flexContainer {
    display: flex;
    flex-direction: row;
}

.inputField {
    flex: 1;
    
    
}
input[type=password]{
    width: 95%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
button[type=submit]{
    width: 95%;
    
     
}
.alert11{
    color: red;
}
.error{
    
    text-align: left;
}
.center{
    align-content: center;
    align-items: center;
    justify-items: center;
    margin-left: 30;
}

div.fade.in.modal {
    display:flex !important;
  }
  
  .modal-dialog {
    margin: auto;
  }
  .modal{
      margin-top: 40px;
     
      
      
  }
  .modal.Title{
      margin-top: '0px';
  }
  .modal.body{
      align-items: center;
      justify-content: center;
      align-content: center;

  }
  .searchinput input[type=text]{
    box-sizing: border-box;
    -webkit-transition: 1s;
    transition: 1s;
    outline: none;
    color: #424242;
    width: 100%;
    border-radius: 10px; 
    border:1px solid #ccc;
  }
  .searchinput input[type=text]:hover{
    width: 100%;
    color: #424242;
    border-radius: 30px; 
    border:1px solid #ccc;
  }
  .searchinput input[type=text]:focus{
   
    color: #424242;
    width: 100%;
    border:1px solid #ccc;
  }
  .searchinput button{
    outline: none;
    box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
    margin-top: 10px;
    width: 30%;
    height: 50px;
    border-radius: 30px;
  }
  .selectop{
      margin-left: 20px;
      margin-right:10px ;

  }
  .selectop select{
      outline: none;
  }
  select{
      width: 85%;
      height: 30px;
  }
  #button{
    font-size: 12px;
    font-family: work Sans;
  }
  input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 32px;
	height: 32px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}
.optionowner{
    display: block;
  position: absolute;
  list-style: none;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}
.demo-app-main {
    flex-grow: 1;
    padding: 3em;
  }
*/


button{
    outline: none;
}

@media screen and (max-width: 550px){
    .sidebar{
        display: none;

    }
}
.optionowner{
  display: block;
position: absolute;
list-style: none;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
padding: 12px 16px;
z-index: 1;
}

.sidebar  ul{
    list-style: none;
    margin-left: 0px;
    padding-left:0px;
    margin-top: 30px;
}
.sidebar  button{
    outline: none;
    color: white;
    width: 100%;
    height:50px;
    background-color: transparent;
    border:0px;
    margin-left: 0px;
    padding-left:0px;
}
.registerspan{
    font-size: 20;
    font-weight: bold;

}
.sidebar  button:hover,
.sidebar button:focus,
.sidebar  button:active{
    background:white;
    color:black;
    color:"blue";
    border:0px;
    
}
.side-margin{
    margin-top:0px;
}


@media screen and (min-width: 550px){.sidebar{
    height:100%;
    background:white;
    box-shadow:2px 0px 5px rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left: 0;
    width:15% ;

}}

  @media print {
	
    body * {
        visibility: hidden;
          
      }
      .capture  * {
        visibility: visible;
        
       
       
        
      }
      @page { }
      .capture {
        position: absolute;
        margin-top: 200px;
       

        margin-right: 0px;
        width: 250mm;
        height: 297mm;
        
      }
     
    
}
