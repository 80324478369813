/* font used for stepswatch logo */
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@600;700&display=swap");

.form-group > label {
  bottom: 34px;
  left: 15px;
  position: relative;
  background-color: white;
  padding: 0px 5px 0px 5px;
  transition: 0.2s;
  pointer-events: none;
  /* z-index: 20; */
  bottom: 53px;
  font-size: 14px;
}

.tooltip {
  background-color: white;
  padding: 0 !important;
}
.md-form {
  margin: 0% !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 10px;
}

.fc-button-primary {
  background-color: #304391 !important;
  text-transform: capitalize !important;
}

.react-tel-input > input {
  width: 100% !important;
}

#capture .fc-day-today {
  background-color: white !important;
}

input:disabled {
  color: #a5abb0 !important;
}

button:active {
  outline: none !important;
}

.btn {
  text-transform: capitalize !important;
  font-size: 0.9rem !important;
}

h2.fc-toolbar-title {
  font-size: 1.5em !important;
}

.animatedCircle:hover {
  transition: transform 125ms;
  transform: translateY(-50px);
  background-color: red;
}
.modal-header {
  padding: 0.5rem 0.75rem !important;
}

.modal-title > p {
  font-size: 18px !important;
}
.notes_content > p {
  margin-bottom: 0px;
}
.btn:hover {
  z-index: unset !important;
}
.buttonHover:hover {
  z-index: unset !important;
}
