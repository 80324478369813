@use "sass:map";

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
);

.button {
	flex: 1;
	background-color: transparent;
	border: none;
	padding: 8px 0px;
	margin: 0px 8px;
	color: lightgray;
}
.button:focus {
	outline-color: transparent;
}
.active {
	border-bottom: 3px solid indigo;
	color: black;
}

.card {
	width: calc(100% - 20px);
	height: 100%;
	// overflow-y: scroll;
	margin: 10px;
	background-color: white;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	display: grid;
	padding: 20px;
	grid-template-columns: 1fr 1fr;
	gap: 0px 20px;
	@media screen and (max-width: map.get($grid-breakpoints, md)) {
		// display: block;
		display: flex;
		flex-direction: column;
	}
}

.form {
	height: 100%;
	overflow: scroll;
}
