.table {
	width: calc(100% - 20px);
	/* margin: 20px; */
	table-layout: fixed;
	font-size: 12px !important;
}

.table > tbody > tr {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #d8d8d8;
	padding-bottom: 10px;
}

.table > thead {
	padding-bottom: 10px;
}

.table > thead > tr > th {
	font-size: 13px !important;
	
	font-weight: 600;
	cursor: pointer;
	position: static;
	border-bottom: 2px solid #ddd; /* Adjust thickness and color */
	top: 0px;
	padding-bottom: 12px;
	background-color: white;
	z-index: 10;
	line-height: 1.5;
	
	
	
    vertical-align: top; /* Ensures vertical alignment in the top */
    white-space: normal; /* Allows wrapping */
     
    line-height: normal; /* Prevents text from sticking at the top */
	
}
.table > thead > tr > th::after {
	content: "";
	width: 100%;
	left: 0px;
	height: 1px;
	position: absolute;
	background-color: #1f1f1f;
	top: 35px;
}
.table > td {
	vertical-align: center;
}
.table > tbody > tr > td {
	margin: 16px 0px;
	padding: 10px 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px !important;
	overflow: hidden;
	font-weight: 400;
}
.table > tbody > tr > td:last-child {
	overflow: unset;
}
.table > tbody > tr:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}
.table > tbody > tr > td > ul > li > p {
	text-overflow: ellipsis;
	overflow: hidden;
}
/* .table > tbody {
	max-height: 100px !important;
} */
@media only screen and (max-width: 768px) {
	.table {
		height: 50%;
		display: block;
		width: 100%;
		/* margin: 0 10px; */
		overflow-x: auto;
		white-space: nowrap;
	}
	.table > thead > tr > th {
		font-size: 12px !important;
		min-width: 100px !important;
	}
	.table > thead > tr > td {
		font-size: 12px !important;
		min-width: 100px !important;
	}
	.table > tbody > tr > td {
		margin: 8px 0px;
		padding: 8px 0px;
		font-size: 12px !important;
	}
}

.tableContainer {
	height: 100%;
}
