@use "sass:map";

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
);

.labels {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;

	@media screen and (max-width: map.get($grid-breakpoints, sm)) {
		display: block;
	}
}

.generate {
	margin-right: auto;
}

.month {
	flex-grow: 1;
}
