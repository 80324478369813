@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@600;700&display=swap);
/* font used for stepswatch logo */

.form-group > label {
  bottom: 34px;
  left: 15px;
  position: relative;
  background-color: white;
  padding: 0px 5px 0px 5px;
  transition: 0.2s;
  pointer-events: none;
  /* z-index: 20; */
  bottom: 53px;
  font-size: 14px;
}

.tooltip {
  background-color: white;
  padding: 0 !important;
}
.md-form {
  margin: 0% !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 10px;
}

.fc-button-primary {
  background-color: #304391 !important;
  text-transform: capitalize !important;
}

.react-tel-input > input {
  width: 100% !important;
}

#capture .fc-day-today {
  background-color: white !important;
}

input:disabled {
  color: #a5abb0 !important;
}

button:active {
  outline: none !important;
}

.btn {
  text-transform: capitalize !important;
  font-size: 0.9rem !important;
}

h2.fc-toolbar-title {
  font-size: 1.5em !important;
}

.animatedCircle:hover {
  transition: transform 125ms;
  transform: translateY(-50px);
  background-color: red;
}
.modal-header {
  padding: 0.5rem 0.75rem !important;
}

.modal-title > p {
  font-size: 18px !important;
}
.notes_content > p {
  margin-bottom: 0px;
}
.btn:hover {
  z-index: unset !important;
}
.buttonHover:hover {
  z-index: unset !important;
}

/*#menubar{
    color: black;
    background-color: #bfdaff;
    height: 50px;
    font-size:large;
    font-weight: 600;
}*/

body {
  font-size: 14px !important;
}
.form-control {
  font-size: 14px !important;
}
.custom-select {
  font-size: 14px;
}
#sidebar {
  margin-top: 50px;
  background-image: url(/static/media/11.2ac63345.jpg);
  background-size: 100% 100%;
  width: 220px;
  font-size: medium;
  font-weight: 400;
}


/*@media screen and (min-width: 550px){
    .log-margin{
        margin-top: 200px;
        
        position: relative;
        padding-left: 20;
        opacity: 0.9;
        z-index: 200;
    
    }
}

 .row-content{
     margin-top:100px;
     
 }
 .row-list{
     margin-top: 10px;
 }

.navcolor{
    color: black;
    margin: 3;

}
 
  .flexContainer {
    display: flex;
    flex-direction: row;
}

.inputField {
    flex: 1;
    
    
}
input[type=password]{
    width: 95%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
button[type=submit]{
    width: 95%;
    
     
}
.alert11{
    color: red;
}
.error{
    
    text-align: left;
}
.center{
    align-content: center;
    align-items: center;
    justify-items: center;
    margin-left: 30;
}

div.fade.in.modal {
    display:flex !important;
  }
  
  .modal-dialog {
    margin: auto;
  }
  .modal{
      margin-top: 40px;
     
      
      
  }
  .modal.Title{
      margin-top: '0px';
  }
  .modal.body{
      align-items: center;
      justify-content: center;
      align-content: center;

  }
  .searchinput input[type=text]{
    box-sizing: border-box;
    -webkit-transition: 1s;
    transition: 1s;
    outline: none;
    color: #424242;
    width: 100%;
    border-radius: 10px; 
    border:1px solid #ccc;
  }
  .searchinput input[type=text]:hover{
    width: 100%;
    color: #424242;
    border-radius: 30px; 
    border:1px solid #ccc;
  }
  .searchinput input[type=text]:focus{
   
    color: #424242;
    width: 100%;
    border:1px solid #ccc;
  }
  .searchinput button{
    outline: none;
    box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
    margin-top: 10px;
    width: 30%;
    height: 50px;
    border-radius: 30px;
  }
  .selectop{
      margin-left: 20px;
      margin-right:10px ;

  }
  .selectop select{
      outline: none;
  }
  select{
      width: 85%;
      height: 30px;
  }
  #button{
    font-size: 12px;
    font-family: work Sans;
  }
  input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 32px;
	height: 32px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}
.optionowner{
    display: block;
  position: absolute;
  list-style: none;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}
.demo-app-main {
    flex-grow: 1;
    padding: 3em;
  }
*/


button{
    outline: none;
}

@media screen and (max-width: 550px){
    .sidebar{
        display: none;

    }
}
.optionowner{
  display: block;
position: absolute;
list-style: none;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
padding: 12px 16px;
z-index: 1;
}

.sidebar  ul{
    list-style: none;
    margin-left: 0px;
    padding-left:0px;
    margin-top: 30px;
}
.sidebar  button{
    outline: none;
    color: white;
    width: 100%;
    height:50px;
    background-color: transparent;
    border:0px;
    margin-left: 0px;
    padding-left:0px;
}
.registerspan{
    font-size: 20;
    font-weight: bold;

}
.sidebar  button:hover,
.sidebar button:focus,
.sidebar  button:active{
    background:white;
    color:black;
    color:"blue";
    border:0px;
    
}
.side-margin{
    margin-top:0px;
}


@media screen and (min-width: 550px){.sidebar{
    height:100%;
    background:white;
    box-shadow:2px 0px 5px rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left: 0;
    width:15% ;

}}

  @media print {
	
    body * {
        visibility: hidden;
          
      }
      .capture  * {
        visibility: visible;
        
       
       
        
      }
      @page { }
      .capture {
        position: absolute;
        margin-top: 200px;
       

        margin-right: 0px;
        width: 250mm;
        height: 297mm;
        
      }
     
    
}

.Logincomponent_design__1JA99{display:block}@media screen and (max-width: 576px){.Logincomponent_design__1JA99{display:none}}
.loading {
    position: absolute;
    
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    height: 30px;
    width: 30px;
    border: 2px solid #ddd;
    border-left-color: #009688;
    border-radius: 30px; /* border-radius: 50% */
    animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
  }
  @keyframes animation-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (min-width: 550px){
    .row-loading{
      margin-top: 30%;
      margin-left:35% ;
      
    } 
}
@media screen and (max-width: 550px){
  .row-loading{
    margin-top: 100%;
    
    
  } 
}
.secedit{
  margin-top: 30%;
  margin-left: 10%;
}
/*input[type=text] {
  width: 95%;
  
  font: size 12;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
select {
  width: 95%;
  
  font: size 12;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font-family: Work Sans; 
  
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
textarea{
  width: 85%;
  
  font: size 12;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font-family: Work Sans; 
  
  
  display: inline-block;
  
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=text]::placeholder {
  
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}

input[type=text]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
select:hover {
  width: 95%;
  background: #dddddd;
  
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
select::placeholder {
  
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}

input[type=email] {
    width: 95%;
    padding: 8px;
    box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font: size 12;
  font-family: Work Sans; 
    padding: 12px 20px;
    margin: 8px 10px ;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
  }
  input[type=email]::placeholder{
    padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  }
  input[type=email]:hover {
    width: 95%;
    background: #dddddd;
    padding: 12px 20px;
    margin: 8px 10px ;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
  }
input[type=number] {
  width: 95%;
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=number]::placeholder {
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}
input[type=number]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=date] {
  width: 95%;
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=date]::placeholder {
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}
input[type=date]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=password]{
  width: 95%;
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=password]::placeholder{
  padding: 8px;
  font: size 12;
  font-family: Work Sans;
}
input[type=password]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
 
  
 
  @media screen and (min-width: 550px){
  .selectc{
    width: 100px;
    margin-top:5px;
  }}
  .row-contentloading{
    align-content: center;
    margin-top: 500px;
  }
  .navbar {
    
    background-color: white;
    box-shadow:2px 0px 5px rgba(0,0,0,0.5);
    
  }
  
.navbar-dark .navbar-nav .nav-link{
  color: #161a25;
  color: rgba(16,25,38,.5);
}
.navbar-dark .navbar-nav .active .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link{
color: rgba(16,25,38,1);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover{
  color: blue; 
}

  .parent{
    margin-top: 30px;
  }
  .center{
    margin-top: 20px;
   margin-left: 40%;
  }
*/

.required {
  color: red;
}
#container {
  visibility: hidden;
}
h1,h2,h3,h4,h5,h6{
  
  color:black;
  
  font: size 12;
  font-family: Roboto, sans-serif;
}
.MyDay_gridWrapper__17qIF{display:grid;width:100%;height:100%;overflow:auto;grid-gap:10px;gap:10px;grid-template-columns:80% calc(20% - 10px);grid-template-rows:15% 25% 25% 35%}@media screen and (max-width: 768px){.MyDay_gridWrapper__17qIF{display:flex;flex-direction:column}}.MyDay_header__3rfNp{display:flex;flex-direction:row;align-items:center}@media screen and (max-width: 768px){.MyDay_header__3rfNp{flex-direction:column;align-items:flex-start}}
.Table_table__1N5lW {
	width: calc(100% - 20px);
	/* margin: 20px; */
	table-layout: fixed;
	font-size: 12px !important;
}

.Table_table__1N5lW > tbody > tr {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #d8d8d8;
	padding-bottom: 10px;
}

.Table_table__1N5lW > thead {
	padding-bottom: 10px;
}

.Table_table__1N5lW > thead > tr > th {
	font-size: 13px !important;
	
	font-weight: 600;
	cursor: pointer;
	position: static;
	border-bottom: 2px solid #ddd; /* Adjust thickness and color */
	top: 0px;
	padding-bottom: 12px;
	background-color: white;
	z-index: 10;
	line-height: 1.5;
	
	
	
    vertical-align: top; /* Ensures vertical alignment in the top */
    white-space: normal; /* Allows wrapping */
     
    line-height: normal; /* Prevents text from sticking at the top */
	
}
.Table_table__1N5lW > thead > tr > th::after {
	content: "";
	width: 100%;
	left: 0px;
	height: 1px;
	position: absolute;
	background-color: #1f1f1f;
	top: 35px;
}
.Table_table__1N5lW > td {
	vertical-align: center;
}
.Table_table__1N5lW > tbody > tr > td {
	margin: 16px 0px;
	padding: 10px 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px !important;
	overflow: hidden;
	font-weight: 400;
}
.Table_table__1N5lW > tbody > tr > td:last-child {
	overflow: unset;
}
.Table_table__1N5lW > tbody > tr:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}
.Table_table__1N5lW > tbody > tr > td > ul > li > p {
	text-overflow: ellipsis;
	overflow: hidden;
}
/* .table > tbody {
	max-height: 100px !important;
} */
@media only screen and (max-width: 768px) {
	.Table_table__1N5lW {
		height: 50%;
		display: block;
		width: 100%;
		/* margin: 0 10px; */
		overflow-x: auto;
		white-space: nowrap;
	}
	.Table_table__1N5lW > thead > tr > th {
		font-size: 12px !important;
		min-width: 100px !important;
	}
	.Table_table__1N5lW > thead > tr > td {
		font-size: 12px !important;
		min-width: 100px !important;
	}
	.Table_table__1N5lW > tbody > tr > td {
		margin: 8px 0px;
		padding: 8px 0px;
		font-size: 12px !important;
	}
}

.Table_tableContainer__BUMMo {
	height: 100%;
}

.searchBar_search__17k-F::placeholder{color:#d1d5db !important}.searchBar_search__17k-F:focus{outline:#d1d5db 1px solid;border-radius:4px}
.PayRoll_labels__1abPI{display:flex;justify-content:center;align-items:center;padding:5px}@media screen and (max-width: 576px){.PayRoll_labels__1abPI{display:block}}.PayRoll_generate__rIOUS{margin-right:auto}.PayRoll_month__16Wiu{flex-grow:1}
.ChildRegister_button__1TnZj{flex:1 1;background-color:transparent;border:none;padding:8px 0px;margin:0px 8px;color:#d3d3d3}.ChildRegister_button__1TnZj:focus{outline-color:transparent}.ChildRegister_active__EWJWE{border-bottom:3px solid indigo;color:#000}.ChildRegister_card__16dRC{width:calc(100% - 20px);height:100%;margin:10px;background-color:#fff;border-radius:20px;box-shadow:rgba(0,0,0,.1) 0px 4px 12px;display:grid;padding:20px;grid-template-columns:1fr 1fr;grid-gap:0px 20px;gap:0px 20px}@media screen and (max-width: 768px){.ChildRegister_card__16dRC{display:flex;flex-direction:column}}.ChildRegister_form__FkcM3{height:100%;overflow:scroll}
.MainComponent_main___Bvf6{height:100vh;overflow:hidden}
