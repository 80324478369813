@use "sass:map";

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
);

.gridWrapper {
	display: grid;
	width: 100%;
	height: 100%;
	overflow: auto;
	gap: 10px;
	grid-template-columns: 80% calc(20% - 10px);
	grid-template-rows: 15% 25% 25% 35%;
	@media screen and (max-width: map.get($grid-breakpoints, md)) {
		display: flex;
		flex-direction: column;
	}
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	@media screen and (max-width: map.get($grid-breakpoints, md)) {
		flex-direction: column;
		align-items: flex-start;
	}
}
