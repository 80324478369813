/*#menubar{
    color: black;
    background-color: #bfdaff;
    height: 50px;
    font-size:large;
    font-weight: 600;
}*/

body {
  font-size: 14px !important;
}
.form-control {
  font-size: 14px !important;
}
.custom-select {
  font-size: 14px;
}
#sidebar {
  margin-top: 50px;
  background-image: url("./public/11.jpg");
  background-size: 100% 100%;
  width: 220px;
  font-size: medium;
  font-weight: 400;
}
