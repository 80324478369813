/*input[type=text] {
  width: 95%;
  
  font: size 12;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
select {
  width: 95%;
  
  font: size 12;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font-family: Work Sans; 
  
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
textarea{
  width: 85%;
  
  font: size 12;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font-family: Work Sans; 
  
  
  display: inline-block;
  
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=text]::placeholder {
  
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}

input[type=text]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
select:hover {
  width: 95%;
  background: #dddddd;
  
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
select::placeholder {
  
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}

input[type=email] {
    width: 95%;
    padding: 8px;
    box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  font: size 12;
  font-family: Work Sans; 
    padding: 12px 20px;
    margin: 8px 10px ;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
  }
  input[type=email]::placeholder{
    padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  }
  input[type=email]:hover {
    width: 95%;
    background: #dddddd;
    padding: 12px 20px;
    margin: 8px 10px ;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
  }
input[type=number] {
  width: 95%;
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  box-sizing: border-box;
    -webkit-transition: 1s;
  transition: 1s;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=number]::placeholder {
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}
input[type=number]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=date] {
  width: 95%;
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=date]::placeholder {
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
}
input[type=date]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=password]{
  width: 95%;
  padding: 8px;
  font: size 12;
  font-family: Work Sans; 
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=password]::placeholder{
  padding: 8px;
  font: size 12;
  font-family: Work Sans;
}
input[type=password]:hover {
  width: 95%;
  background: #dddddd;
  padding: 12px 20px;
  margin: 8px 10px ;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
 
  
 
  @media screen and (min-width: 550px){
  .selectc{
    width: 100px;
    margin-top:5px;
  }}
  .row-contentloading{
    align-content: center;
    margin-top: 500px;
  }
  .navbar {
    
    background-color: white;
    box-shadow:2px 0px 5px rgba(0,0,0,0.5);
    
  }
  
.navbar-dark .navbar-nav .nav-link{
  color: #161a25;
  color: rgba(16,25,38,.5);
}
.navbar-dark .navbar-nav .active .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link{
color: rgba(16,25,38,1);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover{
  color: blue; 
}

  .parent{
    margin-top: 30px;
  }
  .center{
    margin-top: 20px;
   margin-left: 40%;
  }
*/

.required {
  color: red;
}
#container {
  visibility: hidden;
}
h1,h2,h3,h4,h5,h6{
  
  color:black;
  
  font: size 12;
  font-family: Roboto, sans-serif;
}